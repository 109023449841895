.card {
  margin: 10px 0px;
  color: white;
  z-index: 10;
}

.style-card-title {
  width: 100%;
  color: white;
  z-index: 10;
}

.style-card-text {
  width: 100%;
  color: white;
  z-index: 10;
}

.style-link {
  display: inline-block;
}
.card-body {
  min-height: 350px;
  background-color: #d1c8e9;
}
