.figure-box {
  width: 300px;
  height: 300px + 20px;
  border-radius: 10px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #888888;
  margin: 10px;
  cursor: pointer;
}

.figure-box:hover {
  box-shadow: 0px 0px 30px #888888;
}

.no-hover {
  pointer-events: none;
  box-shadow: 0px 0px 10px #888888;
}

.beg {
  border: 2px solid #0a810a;
}

.int {
  border: 2px solid #ebbf12;
}

.adv {
  border: 2px solid #ff0000;
}

.figure-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  max-width: 300px;
  max-height: 300px;
}

.image-placeholder {
  font-size: 16px;
  color: #777;
}

.figure-image img {
  object-fit: cover;
  color: #a39d9d;
  font: 20px Impact;
}

.figure-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.figure-image h2 {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.figure-image p {
  font-size: 14px;
}

.teacher {
  color: #777;
  font-size: 14px;
  font-style: italic;
  margin: 0px;
}

hr {
  border: 1px solid #777;
  margin: 0px;
}
