.react-multi-carousel-list {
  margin: 10px;
}

.home-page-title {
  color: white;
}

button {
  margin: 10px;
}

a {
  text-decoration: none;
  color: white;
}

@media (max-width: 575px) {
  /* Change the height for screens with a width of 768px or less */

  .card-title {
    font-size: 9vw;
  }
}

.react-multi-carousel-dot-list {
  height: 400px;
  position: absolute;
  bottom: -380px; /* Adjust this value to move the dots lower */
}

.custom-carousel {
  height: 400px;
}
