.soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  background-color: #e5e5f7;
  opacity: 0.1;
  background-image: linear-gradient(
      30deg,
      #676fff 12%,
      transparent 12.5%,
      transparent 87%,
      #676fff 87.5%,
      #676fff
    ),
    linear-gradient(
      150deg,
      #676fff 12%,
      transparent 12.5%,
      transparent 87%,
      #676fff 87.5%,
      #676fff
    ),
    linear-gradient(
      30deg,
      #676fff 12%,
      transparent 12.5%,
      transparent 87%,
      #676fff 87.5%,
      #676fff
    ),
    linear-gradient(
      150deg,
      #676fff 12%,
      transparent 12.5%,
      transparent 87%,
      #676fff 87.5%,
      #676fff
    ),
    linear-gradient(
      60deg,
      #676fff77 25%,
      transparent 25.5%,
      transparent 75%,
      #676fff77 75%,
      #676fff77
    ),
    linear-gradient(
      60deg,
      #676fff77 25%,
      transparent 25.5%,
      transparent 75%,
      #676fff77 75%,
      #676fff77
    );
  background-size: 8px 14px;
  background-position: 0 0, 0 0, 4px 7px, 4px 7px, 0 0, 4px 7px;
}

.soon h1 {
  color: #fff; /* Text color */
  font-size: 4rem; /* Adjust the font size as needed */
  text-align: center;
  padding: 20px; /* Add some padding for spacing */
  -webkit-text-stroke: 5px #0035ff; /* width and color */
  font-family: sans;
  font-weight: bold;
}
